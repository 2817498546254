<template>
  <div>
    <!-- dataTable -->
    <div class="tw-container">
      <div class="management-datatable">
        <DataTable
          class="p-datatable-sm"
          :scrollHeight="`${scrollHeight}px`"
          :value="registrationSituationList"
          dataKey="id"
          :loading="dataTabelLoading"
          :rowHover="true"
          :paginator="true"
          paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
          :rows="100"
          :rowsPerPageOptions="[20, 50, 100]"
          :scrollable="true"
          currentPageReportTemplate="從第 {first} 筆到 第 {last} 筆，總共 {totalRecords} 筆"
          v-model:filters="searchGlobal"
          filterDisplay="menue"
          stateStorage="local"
          stateKey="dt-state-registrationSituation-local"
          sortField="createTime"
          :sortOrder="-1"
        >
          <template #header>
            <div class="d-flex align-items-center">
              <input
                class="form-control me-2"
                v-model="searchGlobal['global'].value"
                placeholder="表格搜尋: 任何欄位都能搜"
                style="max-width: 99%"
              />
            </div>
          </template>
          <!-- ID -->
          <Column
            field="id"
            header="ID"
            sortable
            style="min-width: 55px; max-width: 70px"
          ></Column>
          <!-- 姓名 -->
          <Column
            field="name"
            header="姓名"
            sortable
            style="min-width: 100px"
          ></Column>
          <!-- 註冊時間 -->
          <Column
            field="createTime"
            header="註冊時間"
            sortable
            style="min-width: 110px"
          ></Column>
          <!-- 信箱確認否 -->
          <Column
            field="verify"
            header="信箱確認否"
            sortable
            style="min-width: 60px"
          >
            <template #body="{ data }">
              <span
                class="fw-bolder"
                :class="data.verify ? 'text-success' : 'text-danger'"
                >{{ data.verify }}</span
              >
            </template>
          </Column>
          <!-- FB帳號 -->
          <Column
            field="facebookName"
            header="FB帳號"
            sortable
            style="min-width: 110px"
          >
            <template #body="{ data }">
              <span v-if="data.facebookName">{{ data.facebookName }}</span>
              <span class="badge bg-secondary me-1 mb-1" v-else>null</span>
            </template>
          </Column>
          <!-- LINE帳號 -->
          <Column
            field="lineName"
            header="LINE帳號"
            sortable
            style="min-width: 110px"
          >
            <template #body="{ data }">
              <span v-if="data.lineName">{{ data.lineName }}</span>
              <span class="badge bg-secondary me-1 mb-1" v-else>null</span>
            </template>
          </Column>
          <!-- lineNotify -->
          <Column
            field="lineNotify"
            header="LineNotify"
            sortable
            style="min-width: 110px"
          >
            <template #body="{ data }">
              <span
                class="fw-bolder"
                :class="data.lineNotify ? 'text-success' : 'text-danger'"
                >{{ data.lineNotify }}</span
              >
            </template>
          </Column>
          <!-- 賣場列表 -->
          <Column
            field="stores"
            header="賣場列表"
            sortable
            style="min-width: 150px"
          >
            <template #body="{ data }">
              <ol v-for="(item, key) in data.stores" :key="key">
                <li class="mb-1">{{ key + 1 }}. {{ item }}</li>
              </ol>
            </template>
          </Column>
        </DataTable>
      </div>
    </div>
  </div>
</template>

<script>
// dataTable
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import ColumnGroup from 'primevue/columngroup';     //optional for column grouping
import Row from 'primevue/row';                     //optional for row
import { FilterMatchMode } from 'primevue/api';
// api
import { parseToken, sortoutParseTokenResult } from '../../../methods/API/parseToken'

export default {
  components: {DataTable, Column, ColumnGroup, Row},
  data() {
    return {
      // data
      b_serverToken: '',
      // dataTable
      scrollWidth: 0,
      scrollHeight: 0,
      dataTabelLoading: false,
      registrationSituationList: [],
      searchGlobal: {
        global: {
          value: null,
          matchMode: FilterMatchMode.CONTAINS
        }
      },
    }
  },
  created() {
    this.initialization()
    this.getUserInfo()
    this.getRegistrationSituation()
  },
  unmounted() {
    // 將 dataTable 的紀錄清除
    localStorage.removeItem('dt-state-registrationSituation-local')
  },
  methods: {
    // 初始化
    initialization() {
      this.b_serverToken = this.$methods.getCookie('b_serverToken') // 待確認
      // 紀錄 dataTabel 高度
      this.scrollHeight = window.innerHeight - 210
      // 將 dataTable 的紀錄清除
      localStorage.removeItem('dt-state-registrationSituation-local')
    },
    // 取得使用者資訊
    getUserInfo() {
      this.$methods.switchLoading('show')
      const result = parseToken(this.b_serverToken)
      result.then(res => {
        const result = sortoutParseTokenResult(res)
        this.userInfo = result
      }).catch(err => {
        console.log(err.responseJSON)
        this.SweetAlert('other', err.responseJSON.message)
        this.$methods.switchLoading('hide')
      })
    },
    // 取得註冊情況
    getRegistrationSituation() {
      this.dataTabelLoading = true
      this.$methods.switchLoading('show')
      const vm = this
      const getRegistrationSituationApi = `${process.env.VUE_APP_API}/report/getRegistrationSituation`
      const header = {
        authorization: this.b_serverToken
      }
      $.ajax({
        type: 'GET',
        async: true,
        url: getRegistrationSituationApi,
        headers: header,
        processData: false,  // FormData 特有
        contentType: false,  // FormData 特有
        success: function(res) {
          console.log(res)
          if (res.code === '200') {
            const registrationSituations = res.data
            vm.registrationSituationMap(registrationSituations)
          } else {
            vm.SweetAlert(res.code, res.message)
            vm.$methods.switchLoading('hide')
          }
        },
        error: function(err) {
          console.log(err.responseJSON)
          vm.SweetAlert(err.responseJSON.code, err.responseJSON.message)
          vm.$methods.switchLoading('hide')
        },
      })
    },
    // 整理註冊情況資料
    registrationSituationMap(registrationSituations) {
      this.registrationSituationList = []
      registrationSituations.forEach(item => {
        // 原資料
        const object = {
          createTime: this.$methods.moment(item.createTime).format("YYYY-MM-DD HH:mm:ss"),
          facebookName: item.facebookName,
          id: item.id,
          lineName: item.lineName,
          lineNotify: item.lineNotify,
          name: item.name,
          stores: item.stores,
          verify: item.verify
        }
        this.registrationSituationList.push(JSON.parse(JSON.stringify(object)))
      })
      this.dataTabelLoading = false
      this.$methods.switchLoading('hide')
    }
  }
}
</script>